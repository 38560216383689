@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

table .MuiTableRow-head {
  background-color: #002B52;
  font-family: 'Inter' !important;
}

table .MuiTableRow-head th {
  color: #fff;
  padding: 12px 16px;
  font-family: 'Inter' !important;
}

table .MuiTableCell-body {
  font-family: 'Inter' !important;
}

table tr:nth-child(even) {
  background-color: #F2F9FF;
}
body {
  font-family: 'Inter' !important;
}

.MuiButton-label {
  font-family: 'Inter' !important;
}